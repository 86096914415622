import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { isUndefined } from "lodash";
import { SetIntercomSettings } from "../../../../constants";

const VerifyingUserType = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("VerifyingUserType component mounted");

    const renderRoutes = (data) => {
      if (data.user?.userType === "personal_trainer")
        return "/personal-trainer-profile";
      else if (data.user?.userType === "hospital") return "/hospital-profile";
      else if (data.user?.userType === "clinic") return "/clinic-profile";
      else if (data.user?.userType === "rehabilitation_center")
        return "/rehabilitation-center-profile";
      else if (data.user?.userType === "gym") return "/gym-profile";
      else if (data.user?.userType === "athletic_trainers")
        return "/athletic-trainers-profile";
      else if (data.user?.userType === "wellness_provider")
        return "/wellness-provider-profile";
      else if (data.user?.userType === "physical_therapist")
        return "/physical-therapist-profile";
      else return "/provider-profile";
    };

    const verifyUserType = async () => {
      try {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get("code");

        console.log("Code from URL:", code);

        if (!code) {
          toast.error("Authorization code is missing", {
            position: "top-right",
            autoClose: 12000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          return;
        }

        const serverUrl = process.env.REACT_APP_SERVER_URL;
        const redirect_verify_url = process.env.REACT_APP_REDIRECT_URI;

        const response = await axios.post(
          `${serverUrl}/v1/auth/congnito-login`,
          {},
          {
            headers: {
              Authorization: code,
              "Content-Type": "application/json",
              "redirect-uri": `${redirect_verify_url}/verifiying-user-type`,
            },
          }
        );

        const data = response.data;
        localStorage.setItem("user-data", JSON.stringify(response));

        if (
          data &&
          (data.user.role === "provider" || data.user.role === "patient")
        ) {
          localStorage.setItem("provider", JSON.stringify(data.provider));
          if (data?.user?.membershipId) {
            const res = await axios.get(
              "/v1/public/membership/plan/" + data?.user?.membershipId
            );
            sessionStorage.setItem(
              "membershipDetails",
              JSON.stringify(res.data)
            );
          }

          let phoneNumber =
            data?.provider?.phoneNumber ||
            data?.patient?.phoneNumber ||
            data?.user?.phoneNumber ||
            "";
          let zipcode =
            data.provider?.zipcode ||
            data.patient?.zipcode ||
            data.user?.zipcode ||
            "";
          let slug =
            data?.provider?.slug ||
            data?.patient?.slug ||
            data?.user?.slug ||
            "";
          let userType = data?.provider?.userType || "";

          if (
            data?.user?.userType === "nurse_practitioner" ||
            data?.user?.userType === "orthopedic_surgeon" ||
            data?.user?.userType === "neuro_surgeon" ||
            data?.user?.userType === "primary_care"
          ) {
            if (data.provider?.clinic?.length > 0) {
              if (data.provider?.clinic[0]?.zipcode !== "") {
                zipcode = data.provider?.clinic[0]?.zipcode;
              }
            }
          }
          if (data?.user?.userType === "physical_therapist") {
            if (!isUndefined(data.provider?.rehab_info)) {
              if (data.provider?.rehab_info.zipcode !== "") {
                zipcode = data.provider?.rehab_info.zipcode;
              }
              if (data.provider?.rehab_info?.phoneNumber !== "") {
                phoneNumber = data.provider?.rehab_info?.phoneNumber;
              }
            }
          }
          if (data?.user?.userType === "wellness_provider") {
            if (!isUndefined(data.provider?.business_info)) {
              if (data.provider?.business_info.zipcode !== "") {
                zipcode = data.provider?.business_info.zipcode;
              }
            }
          }

          let user = {
            ...data.user,
            zipcode,
            phoneNumber,
            slug,
            userType,
            planType:
              data.provider?.planType ||
              data.patient?.planType ||
              data.user?.planType ||
              null,
            planStatus:
              data.provider?.planStatus ||
              data.patient?.planStatus ||
              data.user?.planStatus ||
              null,
            isPaid:
              data.provider?.isPaid ||
              data.patient?.isPaid ||
              data.user?.isPaid ||
              false,
            stripeCustomerId:
              data.provider?.stripeCustomerId ||
              data.patient?.stripeCustomerId ||
              data.user?.stripeCustomerId ||
              null,
            stripePaymentStatus:
              data.provider?.stripePaymentStatus ||
              data.patient?.stripePaymentStatus ||
              data.user?.stripePaymentStatus ||
              null,
            stripeSubscriptionId:
              data.provider?.stripeSubscriptionId ||
              data.patient?.stripeSubscriptionId ||
              data.user?.stripeSubscriptionId ||
              null,
          };

          localStorage.setItem("mop-token", JSON.stringify(data?.tokens));
          localStorage.setItem(
            "mop-user",
            JSON.stringify({
              ...user,
              _id: data.user._id || data.user.id,
              userId: data.user.id,
            })
          );
          SetIntercomSettings();
          if (data?.claimProfile) {
            localStorage.setItem(
              "claim-status",
              JSON.stringify(data?.claimProfile)
            );
            localStorage.setItem("claim-id", JSON.stringify(data?.claimId));
          }

          const isUserFirstLogin = data.user.isUserFirstLogin;
          if (isUserFirstLogin === false) {
            const userId =
              data.user.role === "provider"
                ? data.provider.userId
                : data.patient.userId;
            navigate(`/about-user?userId=${userId}`);
          } else {
            if (data?.claimProfile && data?.claimProfile === true) {
              window.location.href = `${renderRoutes(data)}`;
            } else {
              navigate(
                data.user.role === "provider" ? "/pro-home" : "/pat-home"
              );
            }
          }
        } else {
          toast.error("Only providers and patients are allowed", {
            position: "top-right",
            autoClose: 12000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (e) {
        console.error("Error in verifyUserType:", e);
        toast.error("An error occurred while verifying user type", {
          position: "top-right",
          autoClose: 12000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    };

    verifyUserType();
  }, [navigate, location]);

  return null;
};

export default VerifyingUserType;
