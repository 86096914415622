import React, { useEffect } from "react";
import { setupInactivityTracker } from "./utils";

const InactivityTrackerWrapper = ({ children }) => {
  useEffect(() => {
    setupInactivityTracker();
  }, []);

  return <>{children}</>;
};

export default InactivityTrackerWrapper;
