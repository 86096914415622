import React, { createContext, useContext, useReducer, useState } from "react";
import axios from "../../../../util/axiosInst";
import { settingsReducer, initialSettings } from "./settings/settingsReducer";
import useActiveSinkId from "./useActiveSinkId/useActiveSinkId";
import { useFirebaseAuth } from "./useFirebaseAuth/useFirebaseAuth";
import { useLocalStorageState } from "../hooks/useLocalStorageState/useLocalStorageState";
import usePasscodeAuth from "./usePasscodeAuth/usePasscodeAuth";

export const StateContext = createContext(null);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props) {
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState(
    "gallery-view-active-key",
    false
  );
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  );
  const [roomType, setRoomType] = useState();
  const [virtualcare, setVirtualcare] = useState();
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] =
    useLocalStorageState("max-gallery-participants-key", 6);

  const [isKrispEnabled, setIsKrispEnabled] = useState(false);
  const [isKrispInstalled, setIsKrispInstalled] = useState(false);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    virtualcare,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
    isKrispEnabled,
    setIsKrispEnabled,
    isKrispInstalled,
    setIsKrispInstalled,
  };

  if (process.env.REACT_APP_SET_AUTH === "firebase") {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === "passcode") {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getVirtualCareUserName: async (meetingId) => {
        return await axios
          .get("/v1/virtualcare/users/" + meetingId)
          .then(({ data }) => data)
          .catch((err) => setError(err));
      },
      getVirtualCareByToken: async (token) => {
        return await axios
          .get("/v1/virtualcare/token/" + token)
          .then(({ data }) => data)
          .catch((err) => null);
      },
      getToken: async (
        user_identity,
        room_name,
        userID,
        participantID,
        participantToken,
        alert
      ) => {
        const { data } = await axios.post(
          "/v1/twilio/video/token",
          {
            user_identity,
            room_name,
            create_conversation:
              process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true",
            organizerId: userID || null,
            participantId: participantID || null,
            participantToken: participantToken || null,
            alert,
          },
          {
            headers: {
              "content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        return data;
      },
      updateRecordingRules: async (room_sid, rules) => {
        return await axios
          .post(
            "/v1/twilio/video/recordingrules",
            { room_sid, rules },
            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then(async (res) => {
            if (!res.ok) {
              const recordingError = new Error(
                res.error?.message ||
                  "There was an error updating recording rules"
              );
              recordingError.code = res.error?.code;
              return Promise.reject(recordingError);
            }
            return res;
          })
          .catch((err) => setError(err));
      },
      updateFeedbackStar: async (virtualcare, rating) => {
        return await axios
          .patch(
            "/v1/virtualcare/" + virtualcare?.id + "/rating",
            { rating, ratingBy: virtualcare?.userID },
            {
              headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then(async (res) => {
            return res;
          })
          .catch((err) => setError(err));
      },
    };
  }

  const getToken = (
    name,
    room,
    userID = "",
    participantID = "",
    participantToken = "",
    alert = true
  ) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room, userID, participantID, participantToken, alert)
      .then((res) => {
        setRoomType(res.roomType);
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then((res) => {
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateFeedbackStar = (virtualcare, value) => {
    setIsFetching(true);
    return contextValue
      .updateFeedbackStar(virtualcare, value)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        setError(err);
        return Promise.reject(err);
      });
  };

  const getVirtualCareByToken = (token) => {
    setIsFetching(true);
    return contextValue
      .getVirtualCareByToken(token)
      .then((res) => {
        setVirtualcare(res);
        setIsFetching(false);
        return res;
      })
      .catch((err) => {
        setError("Invalid Token");
        setIsFetching(false);
        return Promise.reject("Invalid Token");
      });
  };

  const getVirtualCareUserName = (meetingID) => {
    return contextValue
      .getVirtualCareUserName(meetingID)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject("Invalid Details");
      });
  };

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
        getToken,
        getVirtualCareByToken,
        updateRecordingRules,
        getVirtualCareUserName,
        updateFeedbackStar,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
