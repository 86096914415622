import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import HelmetTag from "../helmet/Helmet";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import Image from "../../mockup-v3/provider/components/custom/Image";
import moment from "moment";
import { toast } from "react-toastify";
import { PAGE_VARIABLES } from "../../mockup-v3/patient/constants/constant";

const schema = yup
  .object({
    firstName: yup.string().required("First Name is a required field"),
    lastName: yup.string().required("Last Name is a required field"),
    gender: yup.string().required("Gender is a required field"),
    dob: yup.string().required("Date of Birth is a required field"),
    zipcode: yup.string().required("Zipcode is a required field"),
  })
  .required();

const AboutUser = () => {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const disableStatus = location.state?.apiResponse;
  const [password, setPassword] = useState("");
  const statusChanges = disableStatus?.approved ? "approved" : "unspecified";
  const url = process.env.REACT_APP_SERVER_URL;
  const token = JSON.parse(localStorage.getItem("mop-token"))?.access?.token;
  const [settings, setSettings] = useState({
    address: "",
    city: "",
    state: "",
    zipcode: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    language: [],
    ethnicity: [],
  });

  const [checkModal, setCheckModal] = useState({
    date: "",
    month: "",
    year: "",
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("userType", location.state?.userType || location.state);
    if (location.state?.userType === "pain_management_specialist")
      setValue("userType", "pain_management_specialist");
    if (location.state?.apiResponse) {
      setValue("firstName", location.state?.apiResponse?.first_name);
      setValue("lastName", location.state?.apiResponse?.last_name);
      setValue("email", location.state?.apiResponse?.email);
    }
    if (queryParameters.get("email") != null)
      setValue("email", queryParameters.get("email"));
    if (queryParameters.get("firstName") != null)
      setValue("firstName", queryParameters.get("firstName"));
    if (queryParameters.get("lastName") != null)
      setValue("lastName", queryParameters.get("lastName"));
    if (queryParameters.get("role") != null)
      setValue("userType", queryParameters.get("role"));

    getSettings();
  }, []);

  watch((data) => setPassword(data.password));

  const getSettings = async () => {
    const userId = queryParameters.get("userId");
    if (!userId) {
      console.error("userId is missing in the URL");
      return;
    }

    const role_data = JSON.parse(localStorage.getItem("mop-user"))?.role;

    try {
      const { data } = await axios.get(`${url}/v1/${role_data}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCheckModal({
        ...checkModal,
        date: moment(data.dob).format("DD"),
        month: moment(data.dob).format("MM"),
        year: moment(data.dob).format("YYYY"),
      });
      setSettings(data);
    } catch (e) {
      console.error("Error fetching settings:", e);
    }
  };

  const onSubmit = async (formData) => {
    const role_data = JSON.parse(localStorage.getItem("mop-user"))?.role;
    const userId = queryParameters.get("userId");
    if (!userId) {
      console.error("userId is missing in the URL");
      return;
    }

    const { date, month, year } = checkModal;
    if (!date || !month || !year) {
      console.error("Invalid date values:", checkModal);
      return;
    }

    const dateString = `${year}-${month}-${date}T00:00:00Z`;
    let dob;

    try {
      dob = new Date(dateString).toISOString();
    } catch (e) {
      console.error("Error constructing date:", e);
      return;
    }

    const updatedSettings = {
      ...settings,
      firstName: formData.firstName,
      lastName: formData.lastName,
      gender: formData.gender,
      dob: dob,
      zipcode: formData.zipcode,
    };

    try {
      await axios.patch(`${url}/v1/${role_data}/${userId}`, updatedSettings, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.setItem(
        "mop-user",
        JSON.stringify({ ...updatedSettings, role: `${role_data}` })
      );
      PAGE_VARIABLES.AUTH = { ...updatedSettings, role: `${role_data}` };
      toast.success("Profile updated successfully");
      getSettings();
    } catch (e) {
      console.error("Error from catch", e);
    }

    try {
      const cognitoId = JSON.parse(localStorage.getItem("user-data"))?.data
        ?.user?.cognitoId;
      await axios.post(
        `${url}/v1/auth/cognito-update-details`,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          cusCid: cognitoId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      window.location.href =
        role_data === "provider" ? "/pro-home" : "/pat-home";
    } catch (e) {
      console.error("Error from catch", e);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        overflow: "auto",
        paddingTop: "40px",
        margin: "auto",
      }}
    >
      <div
        className="bg-white rounded"
        style={{ padding: "20px", width: "400px", height: "fit-content" }}
      >
        <HelmetTag
          title="Join Our Community of Orthopedic Experts | My Orthopedic Problem"
          content="Register to join our community of patients, orthopedic surgeons, physical therapists, and personal trainers. Accelerate your orthopedic journey with My Orthopedic Problem."
          canonical="https://myorthopedicproblem.com/provider/signup"
          JSONLD={`
              {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "Sign up",
                "url": "https://myorthopedicproblem.com/provider/signup",
                "image": "https://myorthopedicproblem.com/MyOrthopedicProblem.png"
              }
            `}
        />
        <section>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Image
              local={true}
              alt="My Orthopedic Problem"
              className="width-8rem mt-4"
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className="fw-bold my-3" htmlFor="firstName">
              About Info
            </h5>
            <div className="mb-3">
              <label className="fw-bold" htmlFor="firstName">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                className={`form-control py-1 ${
                  errors.firstName && "is-invalid"
                }`}
                placeholder="First name"
                {...register("firstName")}
              />
              <div className="invalid-feedback text-start">
                {errors.firstName?.message}
              </div>
            </div>
            <div className="mb-3">
              <label className="fw-bold" htmlFor="lastName">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                className={`form-control py-1 ${
                  errors.lastName && "is-invalid"
                }`}
                placeholder="Last name"
                {...register("lastName")}
              />
              <div className="invalid-feedback text-start">
                {errors.lastName?.message}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="gender" className="form-label fw-bold">
                Gender
              </label>
              <select
                id="gender"
                className={`form-control ${errors.gender && "is-invalid"}`}
                {...register("gender")}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <div className="invalid-feedback text-start">
                {errors.gender?.message}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="dob" className="form-label fw-bold">
                Date of Birth
              </label>
              <input
                type="date"
                id="dob"
                className={`form-control ${errors.dob && "is-invalid"}`}
                {...register("dob")}
              />
              <div className="invalid-feedback text-start">
                {errors.dob?.message}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="zipcode" className="form-label fw-bold">
                Zipcode
              </label>
              <input
                type="text"
                id="zipcode"
                className={`form-control ${errors.zipcode && "is-invalid"}`}
                placeholder="Enter Zipcode"
                {...register("zipcode")}
              />
              <div className="invalid-feedback text-start">
                {errors.zipcode?.message}
              </div>
            </div>
            <button type="submit" className="btn btn-primary w-100 mt-2">
              Continue
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default AboutUser;
