import React, { useState } from "react";
import MenuBar from "../../assets/images/menu-bars.svg";
import { ReactComponent as Messages } from "../../assets/provider-icons/6.svg";
import { ReactComponent as Home } from "../../assets/patient-icons/sidebar/1.svg";
import { ReactComponent as TalkToProvider } from "../../assets/patient-icons/sidebar/2.svg";
import { ReactComponent as SymtomChecker } from "../../assets/patient-icons/sidebar/3.svg";
import { ReactComponent as EducationLibrary } from "../../assets/patient-icons/sidebar/4.svg";
import { ReactComponent as MedicalSupplies } from "../../assets/patient-icons/sidebar/5.svg";
import { ReactComponent as PastAppointments } from "../../assets/patient-icons/sidebar/6.svg";
import { ReactComponent as HealthProfile } from "../../assets/patient-icons/sidebar/7.svg";
import { ReactComponent as Assessment } from "../../assets/patient-icons/sidebar/8.svg";
import { ReactComponent as Pharmacy } from "../../assets/patient-icons/sidebar/9.svg";
import { ReactComponent as Insurance } from "../../assets/patient-icons/sidebar/10.svg";
import { ReactComponent as Settings } from "../../assets/patient-icons/sidebar/11.svg";

import Logout from "../../assets/images/logout.svg";
import Logo from "../../assets/images/logo_v3.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import { logOutUser } from "../../../util/utils";

const SideBar = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const newPatientsidebar = [
    {
      pathname: "/pat-home",
      img: (
        <Home
          style={{
            stroke: window.location.pathname.includes("pat-home")
              ? "#0071FF"
              : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Home",
    },
    {
      pathname: "/pat-symptom-checker",
      img: (
        <SymtomChecker
          style={{
            stroke: window.location.pathname.includes("pat-symptom-checker")
              ? "#0071FF"
              : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Symptom Checker",
    },
    {
      pathname: "/pat-assessment",
      img: (
        <Assessment
          style={{
            stroke: window.location.pathname.includes("pat-assessment")
              ? "#0071FF"
              : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Assessment",
    },
    {
      pathname: "/pat-talk-to-provider",
      img: (
        <TalkToProvider
          style={{
            stroke: window.location.pathname.includes("pat-talk-to-provider")
              ? "#0071FF"
              : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Talk to a Provider",
    },
    {
      pathname: "/pat-messages",
      img: (
        <Messages
          style={{
            stroke: window.location.pathname.includes("pat-messages")
              ? "#0071FF"
              : "#333131",
          }}
          width="22px"
          height="22px"
          alt="home-icon"
        />
      ),
      label: "Messages",
    },
    {
      pathname: "/pat-pastappointments",
      img: (
        <PastAppointments
          style={{
            stroke: window.location.pathname.includes("pat-pastappointments")
              ? "#0071FF"
              : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Appointments",
    },
    {
      pathname: "/pat-education-library",
      img: (
        <EducationLibrary
          style={{
            stroke: window.location.pathname.includes("pat-education-library")
              ? "#0071FF"
              : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Education Library",
    },
    {
      pathname: "/pat-health-profile",
      img: (
        <HealthProfile
          style={{
            stroke:
              window.location.pathname.includes("pat-health-profile") ||
              window.location.pathname.includes("pat-health-condition") ||
              window.location.pathname.includes("pat-health-files") ||
              window.location.pathname.includes("pat-allergies") ||
              window.location.pathname.includes("pat-medications") ||
              window.location.pathname.includes("pat-surgeries")
                ? "#0071FF"
                : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Health Profile",
    },
    {
      pathname: "/pat-settings",
      img: (
        <Settings
          style={{
            stroke: window.location.pathname.includes("pat-settings")
              ? "#0071FF"
              : "#333131",
          }}
          width="20px"
          height="20px"
          local={true}
          alt="home-icon"
        />
      ),
      label: "Settings",
    },
    // {
    //   pathname: "/pat-medical-supplies",
    //   img: (
    //     <MedicalSupplies
    //       style={{
    //         stroke: window.location.pathname.includes("pat-medical-supplies")
    //           ? "#0071FF"
    //           : "#333131",
    //       }}
    //       width="20px"
    //       height="20px"
    //       local={true}
    //       alt="home-icon"
    //     />
    //   ),
    //   label: "Medical Supplies",
    // },
    // {
    //   pathname: "/pat-pharmacy",
    //   img: (
    //     <Pharmacy
    //       style={{
    //         stroke: window.location.pathname.includes("pat-pharmacy")
    //           ? "#0071FF"
    //           : "#333131",
    //       }}
    //       width="20px"
    //       height="20px"
    //       local={true}
    //       alt="home-icon"
    //     />
    //   ),
    //   label: "Pharmacy",
    // },
    // {
    //   pathname: "/pat-insurance",
    //   img: (
    //     <Insurance
    //       style={{
    //         stroke: window.location.pathname.includes("pat-insurance")
    //           ? "#0071FF"
    //           : "#333131",
    //       }}
    //       width="20px"
    //       height="20px"
    //       local={true}
    //       alt="home-icon"
    //     />
    //   ),
    //   label: "Insurance",
    // },
  ];
  const [collapse, setCollapse] = useState(true);

  return (
    <div className="d-flex flex-column bg-white border h-100 border-top-0 border-bottom-0 border-left-0">
      {collapse ? (
        <img
          alt="mop-logo"
          src={Logo}
          className="mt-2 ms-2"
          width="150px"
          onClick={() => setCollapse(false)}
        />
      ) : (
        <img
          alt="menu-bars"
          src={MenuBar}
          className="mt-2 cursor-pointer"
          style={{ marginLeft: "10px" }}
          local={true}
          width={"30px"}
          onClick={() => setCollapse(true)}
        />
      )}
      <div className="d-flex flex-column justify-content-between vh-100">
        <div>
          <ul
            className="nav nav-pills text-nowrap d-sm-flex d-none flex-column mb-auto"
            style={{ marginTop: "6px" }}
          >
            {newPatientsidebar.map((item, index) => (
              <li className="nav-item p-0">
                <NavLink
                  className="d-flex align-items-center fw-light"
                  onClick={() => navigate(item?.pathname)}
                  style={{ margin: "0.10rem 0 0.10rem 0" }}
                >
                  {item?.img}
                  {collapse && (
                    <h6
                      className={`ms-3 mb-0 my-auto fs-6 fontweight-bold ${
                        location.pathname.includes(item?.pathname)
                          ? "text-primary fontweight-bold"
                          : ""
                      } `}
                    >
                      {item.label}
                    </h6>
                  )}
                </NavLink>
              </li>
            ))}
            <li className="position-fixed bottom-0">
              <NavLink
                onClick={logOutUser}
                className="d-flex align-items-center fw-light"
              >
                <img
                  src={Logout}
                  local={true}
                  width="20px"
                  height="20px"
                  alt="logout"
                />
                {collapse && (
                  <h6 className="ms-3 mt-2 fs-6 fontweight-bold">Log out</h6>
                )}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
