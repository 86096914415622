import React, { useState, useEffect } from "react";
import DeviceSelectionScreen from "./DeviceSelectionScreen/DeviceSelectionScreen";
import IntroContainer from "../IntroContainer/IntroContainer";
import MediaErrorSnackbar from "./MediaErrorSnackbar/MediaErrorSnackbar";
import RoomNameScreen from "./RoomNameScreen/RoomNameScreen";
import { useAppState } from "../../state";
import { useParams } from "react-router-dom";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import PageNotFound from "../PageNotFound/PageNotFound";

export let Steps;
(function (Steps) {
  Steps[(Steps["roomNameStep"] = 0)] = "roomNameStep";
  Steps[(Steps["deviceSelectionStep"] = 1)] = "deviceSelectionStep";
})(Steps || (Steps = {}));

export default function PreJoinScreens() {
  const { user, getVirtualCareByToken } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams();
  const alert = new URLSearchParams(window.location.search).get("_a");
  const [step, setStep] = useState(1);

  const [name, setName] = useState(user?.displayName || "");
  const [userID, setUserID] = useState("");
  const [participantID, setParticipantID] = useState("");
  const [participantToken, setParticipantToken] = useState(null);
  const [roomName, setRoomName] = useState("");
  const [error, setError] = useState(false);

  const [mediaError, setMediaError] = useState();

  const getVirtualCare = () => {
    getVirtualCareByToken(URLRoomName).then((care) => {
      if (care) {
        setStep(Steps.deviceSelectionStep);
        setUserID(care.userID);
        setParticipantID(care.participantID);
        setParticipantToken(care.participantToken);
        setName(care.userName);
        setRoomName(care.meetingId);
      } else {
        setError(true);
      }
    });
  };

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    getVirtualCare();
  }, []);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        console.log(
          `Video call Error acquiring local media: ${error.message}`,
          error
        );
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes("twil.io") && !window.STORYBOOK_ENV) {
      window.history.replaceState(
        null,
        "",
        window.encodeURI(
          `/virtualcare/video/room/${roomName}${window.location.search || ""}`
        )
      );
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <React.Fragment>
      {error && <PageNotFound error={error} />}
      {!error && (
        <IntroContainer>
          <MediaErrorSnackbar error={mediaError} />
          {step === Steps.roomNameStep && (
            <RoomNameScreen
              name={name}
              roomName={roomName}
              setName={setName}
              setRoomName={setRoomName}
              handleSubmit={handleSubmit}
            />
          )}

          {step === Steps.deviceSelectionStep && (
            <DeviceSelectionScreen
              name={name}
              roomName={roomName}
              setStep={setStep}
              userID={userID}
              participantID={participantID}
              participantToken={participantToken}
              alert={alert || true}
            />
          )}
        </IntroContainer>
      )}
    </React.Fragment>
  );
}
