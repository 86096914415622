import React from "react";
import { hydrate, render } from "react-dom";
import "./scss/style.scss";
import "./mockup-v3/assets/styles/style.scss";
import { BrowserRouter } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import PatientApp from "./mockup-v3/patient/App";
import ProviderApp from "./mockup-v3/provider/App";
import LandingPageApp from "./mockup-v3/landing-page/App";
import VirtualCare from "./mockup-v3/virtualcare/App";
import { StoreProvider, createStore } from "easy-peasy";
import { createLogger } from "redux-logger";
import model from "./store";
import InactivityTrackerWrapper from "./util/inactivityTrackerWrapper";

mixpanel.init("dba9e8c550ace1ab6b8b1b67fbc52003", { debug: false });
const store = createStore(model, {
  middleware: [createLogger()],
});

const APP = (
  <React.StrictMode>
    <InactivityTrackerWrapper>
      <BrowserRouter>
        {window.location.pathname.includes("virtualcare") && <VirtualCare />}
        <StoreProvider store={store}>
          {JSON.parse(localStorage.getItem("mop-user"))?.role === "patient" &&
          !window.location.pathname.includes("virtualcare") ? (
            <PatientApp />
          ) : JSON.parse(localStorage.getItem("mop-user"))?.role ===
              "provider" &&
            !window.location.pathname.includes("virtualcare") ? (
            <ProviderApp />
          ) : (
            <LandingPageApp />
          )}
        </StoreProvider>
      </BrowserRouter>
    </InactivityTrackerWrapper>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}
