import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import HelmetTag from "../components/helmet/Helmet";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../util/axiosInst";
import * as yup from "yup";
import { ProvidersList } from "../constants";
import SocialMediaLogin from "../components/SocialMediaLogin";
import PasswordCheck from "../components/common/PasswordCheck";
import { signupUpdate } from "../util/intercom";
import { SwalAlertFunction } from "./SwalAlert";
import Layout from "../mockup-v3/landing-page/components/layout/Layout";

const schema = yup
  .object({
    firstName: yup.string().required("First Name is a required field"),
    lastName: yup.string().required("Last Name is a required field"),
    email: yup
      .string()
      .email("Email must be a email")
      .required("Email is a required field"),
    userType: yup.string(),
    password: yup
      .string()
      .required("Password is a required field")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
  })
  .required();

const Register = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let search = window.location.search;
  const searchParams = search.split("=");
  const queryParameters = new URLSearchParams(location.search);
  const disableStatus = location.state?.apiResponse;
  const [isVisibile, setIsVisibile] = useState(false);
  const [password, setpassword] = useState("");
  const [typepassword, settypepassword] = useState(false);
  const statuschanges = disableStatus?.approved ? "approved" : "unspecified";

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    setValue("userType", location.state?.userType || location.state);
    if (location.state?.userType === "pain_management_specialist")
      setValue("userType", "pain_management_specialist");
    if (location.state?.apiResponse) {
      setValue("firstName", location.state?.apiResponse?.first_name);
      setValue("lastName", location.state?.apiResponse?.last_name);
      setValue("email", location.state?.apiResponse?.email);
    }
    if (queryParameters.get("email") != null)
      setValue(
        "email",
        searchParams[1]?.split("&")[0] || queryParameters.get("email")
      );
    if (queryParameters.get("firstName") != null)
      setValue("firstName", queryParameters.get("firstName"));
    if (queryParameters.get("lastName") != null)
      setValue("lastName", queryParameters.get("lastName"));
    if (queryParameters.get("role") != null)
      setValue("userType", queryParameters.get("role"));

    // eslint-disable-next-line
  }, []);

  watch((data) => setpassword(data.password));

  const onSubmit = async (formData) => {
    formData.role = formData.userType === "patient" ? "patient" : "provider";
    formData.membershipId = location.state?.membershipID || null;
    formData.profile_claimed_status = statuschanges;
    if (disableStatus) {
      formData.claimYourProfile = true;
      formData.claimId = disableStatus?.id;
      formData.userId = disableStatus?.userId;
    }
    try {
      const { data, status } = await axios.post("/v1/auth/register", formData);
      await onSuccessRegister(data, status);
    } catch (e) {
      console.error("err from catch", e);
    }
  };

  const onSuccess = async ({ provider, data }) => {
    const formData = data;
    formData.role = "patient";
    formData.userType = "patient";
    formData.password = "MOP@1234";
    formData.firstName =
      data.first_name || data.firstName || data.given_name || "None";
    formData.lastName =
      data.last_name || data.lastName || data.family_name || "None";
    await onSubmit(formData);
  };

  const onSuccessRegister = async (data, status) => {
    try {
      if (data && data.user) {
        signupUpdate(data.user);
        window.fbq("track", "User Registered Successfully.", {
          name: data.user.firstName + data.user.lastName,
          email: data.user.email,
        });
        SwalAlertFunction("Thank you for signing up", "info", () =>
          navigate(`/patientwelcome/${data.user.firstName}`)
        );
      } else if (status === 200 || status === 201)
        SwalAlertFunction("Thank you for signing up", "info", () =>
          navigate(
            `/patientwelcome/${data.user.firstName + data.user.lastName}`
          )
        );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Layout>
      <div className="wrapper bg-white">
        <HelmetTag
          title="Join Our Community of Orthopedic Experts | My Orthopedic Problem"
          content="Register to join our community of patients, orthopedic surgeons, physical therapists, and personal trainer. Accelerate your orthopedic journey with My Orthopedic Problem."
          canonical="https://myorthopedicproblem.com/provider/signup"
          JSONLD={`  {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Sign up",
          "url": "https://myorthopedicproblem.com/provider/signup",
          "image": "https://myorthopedicproblem.com/MyOrthopedicProblem.png"
        }`}
        />
        <section className="pb-3 my-5">
          <div className="container mt-5 ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row justify-content-center py-md-5">
                <div className="col-sm-6 pt-md-5 pe-md-5 d-none d-md-block resetPassword-text">
                  <div className=" welcome-heading my-md-5 text-center me-md-5 paddingtop-10rem">
                    Welcome
                  </div>
                </div>
                <div className="col-sm-6 pt-md-5 px-md-5 text-center">
                  <div className="ps-md-5  d-flex m-0 m-auto">
                    <div className="bg-white w-100 rounded  p-3 px-md-5">
                      <h2 className="text-primary py-2">Create your account</h2>
                      <div className="mb-3 mt-4">
                        <select
                          disabled="true"
                          className="form-select text-muted"
                          {...register("userType")}
                        >
                          <option value="">Select User Type</option>
                          <option value="patient">Individual</option>
                          {ProvidersList?.map((providerType) => (
                            <option
                              value={providerType?.value}
                              key={providerType?.value}
                            >
                              {providerType?.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-sm-6">
                          <input
                            disabled={disableStatus ? true : false}
                            type="text"
                            id="firstName_indiv"
                            className={`form-control ${
                              errors.firstName && "is-invalid"
                            }`}
                            placeholder="First name"
                            {...register("firstName")}
                          />
                          <div className="invalid-feedback text-start">
                            {errors.firstName?.message}
                          </div>
                        </div>
                        <div className="mb-3 col-sm-6">
                          <input
                            disabled={disableStatus ? true : false}
                            type="text"
                            id="lastName_indiv"
                            className={`form-control ${
                              errors.lastName && "is-invalid"
                            }`}
                            placeholder="Last name"
                            {...register("lastName")}
                          />
                          <div className="invalid-feedback text-start">
                            {errors.lastName?.message}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          id="email_indiv"
                          placeholder="Email"
                          disabled={disableStatus ? true : false}
                          className={`form-control ${
                            errors.email && "is-invalid"
                          }`}
                          {...register("email")}
                        />
                        <div className="invalid-feedback text-start">
                          {errors.email?.message}
                        </div>
                      </div>
                      <div className="input-group mb-3 bg-white">
                        <input
                          type={isVisibile ? "text" : "password"}
                          id="password_indiv"
                          className={`form-control bg-transparent${
                            errors.password && "is-invalid"
                          }`}
                          onClick={() => settypepassword(true)}
                          placeholder="Create password"
                          {...register("password")}
                        />
                        <span
                          className="input-group-text border-top border-end border-bottom cursor-pointer"
                          onClick={() => setIsVisibile(!isVisibile)}
                        >
                          {!isVisibile ? (
                            <i className="far fa-eye text-muted" />
                          ) : (
                            <i className="far fa-eye-slash text-muted"></i>
                          )}
                        </span>
                        <div className="invalid-feedback text-start">
                          {errors.password?.message}
                        </div>
                      </div>
                      <PasswordCheck
                        typepassword={typepassword}
                        password={password}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary w-100 mt-3"
                      >
                        Continue
                      </button>
                      <p className="mt-3 text-center">
                        Already have an account?{" "}
                        <Link
                          to="/login"
                          className="text-decoration-none text-primary"
                        >
                          Log in
                        </Link>
                      </p>
                      <div className="mt-4 loginor d-flex w-100 align-items-center">
                        <span>OR</span>
                      </div>
                      <p className="mt-3 text-center">
                        <div className="row">
                          <div className="col-sm-12">
                            <SocialMediaLogin
                              provider={"google"}
                              onSuccess={onSuccess}
                            />
                          </div>
                          <div className="col-sm-12">
                            <SocialMediaLogin
                              provider={"facebook"}
                              onSuccess={onSuccess}
                            />
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Layout>
  );
};
export default Register;
