import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../../util/axiosInst";
import Carousel from "react-grid-carousel";
import Image from "../custom/Image";

const LatestNews = () => {
  //constant and getting all the latest news.
  const [latestNews, setLatestNews] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const muscleType = async () => {
    setLoader(true);
    const { data } = await axios.get("/v1/public/blog-news/latest?limit=5");
    setLatestNews(data);
    setLoader(false);
  };

  useEffect(() => {
    window.fbq("Latest News Page is viewed");
    muscleType();
  }, []);

  return (
    <section
      className="sec_seven mx-5 p-0"
      style={{ marginTop: "10rem", marginBottom: "7rem" }}
    >
      <h1 className="fontweight-bold text-center display-4 mt-5 pt-5 mb-5">
        Latest News
      </h1>
      <Carousel cols={3} rows={1} gap={10} loop>
        {latestNews.map((item) => (
          <Carousel.Item>
            <Link
              to={`/press/${item.slug}`}
              state={{ data: item }}
              style={{ color: "black", textDecoration: "none" }}
            >
              <div className="px-3">
                <Image
                  width="100%"
                  height={"210px"}
                  src={item?.banner}
                  style={{ borderRadius: "20px" }}
                />
                <p className="pt-1 fw-700">{item?.title}</p>
              </div>
            </Link>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

export default LatestNews;
