import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import HelmetTag from "../components/helmet/Helmet";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../util/axiosInst";
import * as yup from "yup";
import { ProvidersList } from "../constants";
import SocialMediaLogin from "../components/SocialMediaLogin";
import PasswordCheck from "../components/common/PasswordCheck";
import { signupUpdate } from "../util/intercom";
import { SwalAlertFunction } from "./SwalAlert";

const schema = yup
  .object({
    firstName: yup.string().required("First Name is a required field"),
    lastName: yup.string().required("Last Name is a required field"),
    email: yup
      .string()
      .email("Email must be a email")
      .required("Email is a required field"),
    userType: yup.string(),
    password: yup
      .string()
      .required("Password is a required field")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
      ),
  })
  .required();

const Register = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let search = window.location.search;
  const searchParams = search.split("=");
  const queryParameters = new URLSearchParams(location.search);
  const disableStatus = location.state?.apiResponse;
  const [isVisibile, setIsVisibile] = useState(false);
  const [password, setpassword] = useState("");
  const [typepassword, settypepassword] = useState(false);
  const statuschanges = disableStatus?.approved ? "approved" : "unspecified";

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    setValue("userType", location.state?.userType || location.state);
    if (location.state?.userType === "pain_management_specialist")
      setValue("userType", "pain_management_specialist");
    if (location.state?.apiResponse) {
      setValue("firstName", location.state?.apiResponse?.first_name);
      setValue("lastName", location.state?.apiResponse?.last_name);
      setValue("email", location.state?.apiResponse?.email);
    }
    if (queryParameters.get("email") != null)
      setValue(
        "email",
        searchParams[1]?.split("&")[0] || queryParameters.get("email")
      );
    if (queryParameters.get("firstName") != null)
      setValue("firstName", queryParameters.get("firstName"));
    if (queryParameters.get("lastName") != null)
      setValue("lastName", queryParameters.get("lastName"));
    if (queryParameters.get("role") != null)
      setValue("userType", queryParameters.get("role"));

    // eslint-disable-next-line
  }, []);

  watch((data) => setpassword(data.password));

  const onSubmit = async (formData) => {
    formData.role = formData.userType === "patient" ? "patient" : "provider";
    formData.membershipId = location.state?.membershipID || null;
    formData.profile_claimed_status = statuschanges;
    if (disableStatus) {
      formData.claimYourProfile = true;
      formData.claimId = disableStatus?.id;
      formData.userId = disableStatus?.userId;
    }
    try {
      const { data, status } = await axios.post("/v1/auth/register", formData);
      await onSuccessRegister(data, status);
    } catch (e) {
      console.error("err from catch", e);
    }
  };

  const onSuccess = async ({ provider, data }) => {
    const formData = data;
    formData.role = "patient";
    formData.userType = "patient";
    formData.password = "MOP@1234";
    formData.firstName =
      data.first_name || data.firstName || data.given_name || "None";
    formData.lastName =
      data.last_name || data.lastName || data.family_name || "None";
    await onSubmit(formData);
  };

  const onSuccessRegister = async (data, status) => {
    try {
      if (data && data.user) {
        signupUpdate(data.user);
        window.fbq("track", "User Registered Successfully.", {
          name: data.user.firstName + data.user.lastName,
          email: data.user.email,
        });
        SwalAlertFunction("Thank you for signing up", "info", () =>
          navigate(`/patientwelcome/${data.user.firstName}`)
        );
      } else if (status === 200 || status === 201)
        SwalAlertFunction("Thank you for signing up", "info", () =>
          navigate(
            `/patientwelcome/${data.user.firstName + data.user.lastName}`
          )
        );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="bg-white">
      <HelmetTag
        title="Join Our Community of Orthopedic Experts | My Orthopedic Problem"
        content="Register to join our community of patients, orthopedic surgeons, physical therapists, and personal trainer. Accelerate your orthopedic journey with My Orthopedic Problem."
        canonical="https://myorthopedicproblem.com/provider/signup"
        JSONLD={`  {
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Sign up",
          "url": "https://myorthopedicproblem.com/provider/signup",
          "image": "https://myorthopedicproblem.com/MyOrthopedicProblem.png"
        }`}
      />
      <section className="">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="  d-flex m-0 m-auto">
              <div className="bg-white w-100 rounded ">
                <h4 className="text-primary py-2 text-center fw-600">
                  Create your account
                </h4>
                <div className="mb-2">
                  <select
                    disabled="true"
                    className="form-select text-muted py-1"
                    {...register("userType")}
                  >
                    <option value="">Select User Type</option>
                    <option value="patient">Individual</option>
                    {ProvidersList?.map((providerType) => (
                      <option
                        value={providerType?.value}
                        key={providerType?.value}
                      >
                        {providerType?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="row">
                  <div className="mb-2 col-sm-6">
                    <input
                      disabled={disableStatus ? true : false}
                      type="text"
                      id="firstName_indiv"
                      className={`form-control py-1 ${
                        errors.firstName && "is-invalid"
                      }`}
                      placeholder="First name"
                      {...register("firstName")}
                    />
                    <div className="invalid-feedback text-start">
                      {errors.firstName?.message}
                    </div>
                  </div>
                  <div className="mb-2 col-sm-6">
                    <input
                      disabled={disableStatus ? true : false}
                      type="text"
                      id="lastName_indiv"
                      className={`form-control py-1 ${
                        errors.lastName && "is-invalid"
                      }`}
                      placeholder="Last name"
                      {...register("lastName")}
                    />
                    <div className="invalid-feedback text-start">
                      {errors.lastName?.message}
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <input
                    type="text"
                    id="email_indiv"
                    placeholder="Email"
                    disabled={disableStatus ? true : false}
                    className={`form-control py-1 ${
                      errors.email && "is-invalid"
                    }`}
                    {...register("email")}
                  />
                  <div className="invalid-feedback text-start">
                    {errors.email?.message}
                  </div>
                </div>
                <div className="input-group mb-2 bg-white">
                  <input
                    type={isVisibile ? "text" : "password"}
                    id="password_indiv"
                    className={`form-control py-1 bg-transparent${
                      errors.password && "is-invalid"
                    }`}
                    onClick={() => settypepassword(true)}
                    placeholder="Create password"
                    {...register("password")}
                  />
                  <span
                    className="input-group-text border-top border-end border-bottom cursor-pointer"
                    onClick={() => setIsVisibile(!isVisibile)}
                  >
                    {!isVisibile ? (
                      <i className="far fa-eye text-muted" />
                    ) : (
                      <i className="far fa-eye-slash text-muted"></i>
                    )}
                  </span>
                  <div className="invalid-feedback text-start">
                    {errors.password?.message}
                  </div>
                </div>
                <PasswordCheck
                  typepassword={typepassword}
                  password={password}
                />
                <button type="submit" className="btn btn-primary w-100 mt-2">
                  Continue
                </button>
                <p className="mt-2 mb-1 text-center">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="text-decoration-none text-primary"
                  >
                    Log in
                  </Link>
                </p>
                <div className="mt-2 loginor d-flex w-100 align-items-center">
                  <span>OR</span>
                </div>
                <p className="mt-1 text-center">
                  <div className="row">
                    <div className="col-sm-12">
                      <SocialMediaLogin
                        provider="google"
                        onSuccess={onSuccess}
                      />
                    </div>
                    <div className="col-sm-12">
                      <SocialMediaLogin
                        provider="facebook"
                        onSuccess={onSuccess}
                      />
                    </div>
                  </div>
                </p>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};
export default Register;
