import React, { useState } from "react";
import axios from "axios";
import Select from "react-select";
import { differenceWith } from "lodash";

const CertificationSelect = ({
  setValue,
  name,
  certificationRows,
  index,
  setCertificationRows,
  watch,
  signupData,
  certifications,
}) => {
  const [providerList, setProviderList] = useState([]);
  const getAllProviders = (e) => {
    try {
      if (e?.length >= 3) {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/v1/certification?certification=${e}`,
            {
              headers: {
                Authorization: `Bearer ${signupData?.tokens?.access?.token}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              const result = differenceWith(
                response.data.results,
                certificationRows,
                (obj1, obj2) =>
                  `${obj1?.governingBody}-${obj1?.certification}` === obj2.label
              );
              const providerOptions =
                result &&
                result?.map((item) => {
                  return {
                    value: item.id,
                    label: `${item?.governingBody}-${item?.certification}`,
                  };
                });
              setProviderList(providerOptions);
            }
          });
      }
    } catch (e) {
      console.error("err from catch", e);
    }
  };

  const onChange = (e) => {
    setValue(name, { ...watch(name), label: e.label });
    const array = [...certificationRows];
    array[index] = e;
    setValue("certifications", array);
  };

  return (
    <>
      <label className="form-label">Certification</label>
      <Select
        options={providerList}
        placeholder={
          <>
            <i className="fa fa-solid fa-magnifying-glass pe-2" />
            Search a certification
          </>
        }
        isMulti={false}
        onInputChange={(e) => getAllProviders(e)}
        onChange={onChange}
        value={watch(name) || certifications}
      />
    </>
  );
};

export default CertificationSelect;
