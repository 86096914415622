import React, { useEffect, useState } from "react";
import Layout from "./layout/Layout";
import Footer from "./Home/Footer";

const Cookiepolicy = () => {
  useEffect(() => {
    window.fbq("track", `Cookie Policy Page is opened.`);
    document.getElementById("root").scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className="mt-5 mb-4 flex-fill">
        <section className="h-100">
          <div className="container">
            <div className="row mt-3">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <h3>My Orthopedic Problem Cookie Policy</h3>
                  <div className="d-flex"></div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="bg-white rounded p-5 shadow-lg mb-5 table-responsive">
                  <p>
                    This Cookie Policy explains when and why cookies and similar
                    tracking technologies are used by MOP LLC ("MOP," "we" or
                    "us") and our partners on our websites, applications,
                    advertisements and related services, including
                    Myorthopedicproblem.com, clinekt.com and the mobile
                    optimized versions of these sites and our Mobile Device
                    Applications or "Apps" (we refer to these sites and Apps
                    collectively as the "MOP Sites"). We refer to the MOP Sites,
                    along with the information and services made available to
                    you through the MOP Sites, as the "Services."
                  </p>
                  <p>
                    Please refer to the MOP Privacy Policy to learn more about
                    the use of cookies and other tracking technologies on the
                    MOP Sites and how you can contact us for more information
                  </p>

                  <h5>
                    <u>Cookies</u>
                  </h5>
                  <p>
                    "Cookies" are small data files that are stored on the hard
                    drive of the computer you use to view a website. Every
                    computer that accesses a MOP Site is assigned a different
                    cookie by MOP. Different Cookies serve different purposes:
                  </p>

                  <p>
                    <span>
                      <u>Persistent cookies </u>
                    </span>
                    enable us to recognize you when you visit a MOP Site and
                    remember your preferences. Persistent cookies are stored on
                    your browser or mobile device until you choose to delete
                    them, and otherwise typically delete themselves at
                    expiration.
                  </p>
                  <p>
                    <span>
                      <u>Third-party cookies </u>
                    </span>
                    are placed by someone other than MOP, and may gather
                    browsing activity across multiple websites and sessions.
                    They are usually persistent cookies and are stored until you
                    delete them or they expire based on the time period set in
                    each third-party cookie.
                  </p>
                  <p>
                    "Web Beacons" are graphic image files embedded in a page of
                    a website typically used to monitor activity on the page and
                    send back to its home server (which can belong to the host
                    site, a network advertiser or some other third party)
                    information from your browser, such as the IP address, the
                    URL of the page on which the Web Beacon is located, the type
                    of browser that is accessing the site and the ID number of
                    any cookies on your computer previously placed by that
                    server.
                  </p>
                  <p>
                    "Application SDKs" are mobile application third-party
                    software development kits that are embedded in mobile
                    applications. These app SDKs permit the collection of
                    information about the app itself, activity in the app, and
                    the device the application is running on.
                  </p>
                  <h5>
                    <u>Cookie on the MOP Sites</u>
                  </h5>
                  <p>
                    The cookies on the MOP Sites are generally used for one of
                    the following purposes:
                  </p>
                  <p>
                    Strictly necessary. These cookies are necessary for the MOP
                    Sites to function and are essential to access some areas of
                    the MOP Sites. For example, certain cookies enable us to
                    identify registered members and ensure that they have access
                    to content that is only available to registered members.
                  </p>
                  <p>
                    <u>Important functionality.</u> These cookies enable us to
                    remember your preferences, such as your user name, language
                    or the region you are in, and provide enhanced, more
                    personalized features. They may also be used to provide
                    services you have asked for, such as watching a video.
                  </p>
                  <p>
                    <u>Performance and Analytics.</u>These cookies enable us to
                    analyze how visitors use the MOP Sites and to monitor
                    website performance. We use the information collected by
                    these Cookies to improve the performance of the MOP Sites
                    and our Services.
                  </p>
                  <p>
                    <u>Advertising</u>These cookies are used by MOP and our
                    advertisers to deliver advertisements on the MOP Sites and
                    third party sites that are more relevant to you and your
                    interests based on your MOP account information, content
                    browsing activity and in some cases, other information about
                    you that we or our advertisers may have obtained from other
                    sources. Cookies may be used to limit the number of times
                    you see a particular advertisement and to enable measurement
                    of the effectiveness of the advertising campaign.
                    Information collected through the use of cookies may be
                    collected directly by MOP, our partners or third party
                    advertisers and their ad servers.
                  </p>
                  <p>
                    We use cookies and other tracking technologies to recognize
                    individual users when they access the Services, remember
                    user preferences, keep track of users’ access to and use of
                    the Services, track whether our emails opened and whether
                    links are clicked, ensure that the Services are functioning
                    properly, analyze trends and to personalize the Services,
                    including advertising on the MOP Sites and on third party
                    websites, so that it is relevant to individual user’s
                    interests which may be inferred based on location, prior
                    activity on the MOP Sites and other information that MOP or
                    our partners may have about our users. When you use the
                    Services, we also automatically collect information from
                    your browser or mobile device such as your IP address or
                    unique device identifier, browser information (including
                    referring URL), your preferences and settings, cookies and
                    information about the content you have viewed and actions
                    taken (e.g., search queries, ad engagement, clicks and the
                    associated dates and times). MOP may also collect
                    device-specific information when you install and use an App
                    including your device model, operating system information,
                    advertising ID (which is a unique, user-resettable
                    identification number for advertising associated with a
                    mobile device) and app version and usage information. When
                    enabled by you, we collect precise location information
                    provided by your mobile device, which you may disable
                    through the device settings.
                  </p>
                  <h5>
                    <u>Third Party Cookies</u>
                  </h5>
                  <p>
                    Sponsors or advertisers on the MOP Sites may use their own
                    cookies or other tracking technologies in the banner
                    advertisements and sponsored links and on the pages ("Brand
                    Pages") on the MOP Sites that consist solely of
                    advertisements or other content from our advertisers. This
                    advertiser content may also be served in emails, special
                    promotions or newsletters we send you. Their advertisements
                    may be displayed on the MOP Sites or on other sites that you
                    visit after you visit the MOP Sites. Some advertisers use
                    companies other than MOP to serve their ads and to monitor
                    users' responses to ads, and these ad servers may also
                    collect information through the use of cookies on the MOP
                    Sites.
                  </p>
                  <p>
                    While we generally permit the use of these third party
                    cookies on the assumption that they comply with our
                    Advertising Policy and we assist our advertisers with the
                    placement of cookies on Brand Pages, we do not control how
                    third parties use cookies or how they manage the information
                    they gather through the use of these technologies on the MOP
                    Sites and we may be unable to verify compliance with our
                    Advertising Policy. To see how Google may use information
                    collected through your use of Google's search services on
                    the MOP Sites visit
                    <a
                      href="https://www.google.com/policies/privacy/partners"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.google.com/policies/privacy/partners
                    </a>
                    .
                  </p>
                  <p>
                    Because your browser, App, or device communicates with these
                    third parties respective servers, these companies can
                    collect information, including your IP address, page header
                    information, browser or device information, just as if you
                    had requested their website or used their apps directly.
                    These companies may collect information to deliver you
                    customized ads on both the MOP Sites and non-MOP websites,
                    applications, and services. In some situations, MOP may
                    interact with these companies outside of your browser or
                    device, directly between our web servers. Many of these
                    third parties offer information about their data collection
                    practices, and in some cases, an opt-out, on their
                    respective websites. Please review each company’s privacy
                    policy to better understand the controls available to you.
                    We encourage you to periodically review this Cookie Policy
                    for the latest information on our ad network partners.
                  </p>
                  <h5>
                    <u>Your Choices</u>
                  </h5>
                  <p>
                    Most browser software can be set to reject cookies, and
                    offer instructions on how to reset the browser to reject
                    cookies in the "Help" or "Settings" or "Preferences" section
                    of your browser’s toolbar. If you reject our cookies,
                    certain of the functions and conveniences of our websites
                    may not work properly but you do not have to accept our
                    cookies in order to productively use most features of our
                    websites. It is important to note that in order to use the
                    opt-out services described below, such as the Network
                    Advertising Initiative and the TRUSTed Ads™ program, you
                    must not reject all cookies because these opt-out procedures
                    work by placing cookies on your browser that enable these
                    opt-out procedures to function. The "opt-out cookies" are
                    placed, for example, in order to help prevent the placement
                    of Cookies used for the targeting of advertising..
                  </p>
                  <p>
                    Certain companies that serve ads allow you to prevent them
                    from collecting data through the use of cookies. In order to
                    do so, you must opt-out of such data collection with each
                    individual site. You can also opt out of cookies for several
                    ad servers by visiting the Network
                    <a
                      href="https://optout.networkadvertising.org/?c=1#!%2F"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Advertising Initiative gateway opt-out site or by clicking
                      here:
                    </a>
                    <a
                      href="https://preferences.truste.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://preferences.truste.com/
                    </a>
                    . This website will also allow you to review the privacy
                    policies of these ad servers. You can find additional
                    information and resources about how to opt out of
                    advertising and related cookies by visiting the
                    <a
                      href="https://www.worldprivacyforum.org/2015/08/consumer-tips-top-ten-opt-outs/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      World Privacy Forum's Site
                    </a>
                    . I
                  </p>
                  <p className="backgroundcolor-yellow">
                    In order to give you more control over your privacy choices
                    MOP has implemented a DAA (Digital Advertising Alliance)
                    approved opt-out mechanism for interest-based advertising.
                    At the bottom of every page of the MOP Sites you will see an
                    AdChoices logo (which looks like this: ). In our general MOP
                    App you can find the AdChoices option in the “Settings”
                    screen. By clicking on that logo on the MOP Sites or
                    following the instructions in the general MOP App, you will
                    be presented with a window that will identify all of the
                    companies that MOP uses to direct tailored advertisements to
                    you, both on the MOP Sites and general MOP App as well as on
                    third-party sites. If you would like to stop this tailored
                    advertising from MOP, you can manage these advertisements
                    through the choices in that window. Please note that even if
                    you opt-out of interest-based advertising, you will continue
                    to receive generic advertisements through the Services.
                    Please note that your choice to stop tailored advertising is
                    specific to the browser or mobile device that you are using
                    so if you make a choice to opt out from one computer or
                    mobile device and you want your opt-out to apply to a
                    different computer or mobile device as well please opt out
                    from that computer or mobile device too. By clicking the +
                    symbol next to the name of a vendor in that window you will
                    get information about how and where that vendor assists us
                    with the delivery of advertisements. The MOP Sites do not
                    respond to web browser "do not track" signals. This opt-out
                    mechanism is also available in the general MOP App for iOS
                    and Android, as well as on our mobile optimized sites, but
                    is not currently available on our other Apps
                  </p>
                  <p>
                    You may also control interest-based advertising on your
                    mobile device by enabling the “Limit Ad Tracking” setting in
                    your iOS device’s settings or “Opt out of Ads
                    Personalization” in your Android device’s settings. This
                    will not prevent you from seeing advertisements, but will
                    limit the use of device advertising identifiers to
                    personalize ads based on your interests. If you have
                    questions about how to reject Cookies on your specific
                    device please check with the device manufacturer.
                  </p>
                  <h5>
                    <u>
                      Additional Information for MOP’s Visitors from the
                      European Economic Area (“EEA”)
                    </u>
                  </h5>
                  <p className="backgroundcolor-yellow">
                    If you are a MOP user in the EEA, before cookies are placed
                    on your computer or device, you will be shown a banner
                    requesting that you either accept or decline the placement
                    of cookies. By accepting the placement of cookies you are
                    enabling us to provide the best possible experience and
                    service to you. You may, if you wish, decline the placement
                    of cookies unless those cookies are strictly necessary.
                  </p>
                  <h5>
                    <u>Changes</u>
                  </h5>
                  <p>
                    MOP reserves the right to change this Cookie Policy at any
                    time and any changes will be effective upon being posted
                    unless we advise otherwise. We encourage you to periodically
                    review this Cookie Policy for the latest information about
                    the use of cookies on the MOP Sites.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </Layout>
  );
};

export default Cookiepolicy;
