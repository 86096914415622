import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import withReactContent from "sweetalert2-react-content";
import PasswordCheck from "../common/PasswordCheck";
import axios from "../../util/axiosInst";
import mixpanel from "mixpanel-browser";
import { EducationModalSchema } from "../../util/schema";
import TextInput from "../common/TextInput";
import SocialMediaLogin from "../SocialMediaLogin";
const MySwal = withReactContent(Swal);

const EducationPageModal = () => {
  //ROUTER CONSTANTS
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //BASIC CONSTANTS
  const evaluationCondition = pathname.includes("providers");
  const blogCondition = pathname.includes("blog");
  const educationCondition = pathname.includes("education");
  let educationPageModal = localStorage.getItem("mop-user")
    ? JSON.parse(localStorage.getItem("mop-user"))
    : JSON.parse(localStorage.getItem("education-page"));
  const [isVisibile, setIsVisibile] = useState(false);
  const [password, setpassword] = useState("");
  const [typepassword, settypepassword] = useState(false);

  //USE-HOOK-FORM CONSTANTS
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EducationModalSchema),
  });

  //STORE ACTIONS
  const setEducationPageModal = useStoreActions(
    (actions) => actions.setEducationPageModal
  );
  const setResults = useStoreActions((actions) => actions.setResults);
  const setComments = useStoreActions((actions) => actions.setComments);
  //STORE STATE
  const answers = useStoreState((state) => state.answers);
  const comment = useStoreState((state) => state.comment);
  const selectedMuscleGroupServerId = useStoreState(
    (state) => state.selectedMuscleGroupServerId
  );

  //FUNCTIONS
  const getComments = async () => {
    const { data } = await axios.get(`/v1/public/blog/${comment.id}/comments`);
    setComments(data);
  };

  const patientRegister = async (formData) => {
    if (formData?.password?.length) {
      formData.profile_claimed_status = "unspecified";
      formData.role = "patient";
      formData.userType = "patient";
      const result = await axios
        .post("/v1/auth/register", formData)
        .then((res) => {
          formData.registerStatus = res;
          return res;
        });
      return result;
    }
  };
  const closeEducationModal = () => {
    setpassword("");
    reset();
    document.querySelector("#educationPageModal").classList.remove("show");
    document.querySelector("#educationPageModal").style.display = "none";
    document
      .getElementById("custom-drop")
      .classList.remove("modal-backdrop", "fade", "show");
  };
  const onSuccess = async ({ provider, data }) => {
    const formData = data;
    formData.password = "MOP@1234";
    formData.firstName =
      data.first_name || data.firstName || data.given_name || "None";
    formData.lastName =
      data.last_name || data.lastName || data.family_name || "None";
    formData.zipcode = data.postal_code || "35223";
    await formSubmit(formData);
  };
  const formSubmit = async (formData) => {
    if (evaluationCondition) {
      await patientRegister(formData);
      if (
        formData.registerStatus?.status === 200 ||
        formData.registerStatus?.status === 201
      ) {
        delete formData.profile_claimed_status;
        const response = await axios.post(`v1/public/blog-views`, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          zipcode: formData.zipcode,
          type: "evaluation",
        });
        if (response) {
          MySwal.fire({
            text: "Thank you for creating an account!",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => setResults(null));
        }
        await axios.post("/v1/evaluation/history", {
          evaluationHistoryId: localStorage?.getItem("evaluation-history-id"),
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          zipcode: formData.zipcode,
          request: {
            id: selectedMuscleGroupServerId,
            responses: answers,
          },
        });
        closeEducationModal();
      }
      try {
        window.fbq("track", "Education Page Modal Data is closed.", {
          name: formData.firstName + formData.lastName,
          email: formData.email,
        });
        mixpanel.track("User", {
          type: "Geo Data",
          email: formData.email,
          name: `${formData.zipcode}`,
        });

        mixpanel.track("User", {
          type: "count",
          email: formData.email,
          name: formData.firstName + " " + formData.lastName,
        });
      } catch (error) {}
    } else if (blogCondition) {
      try {
        const response = await axios.post(
          `/v1/public/blog/${comment.id}/comments`,
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            zipcode: formData.zipcode,
            message: comment.value,
          }
        );
        if (response.status === 200) {
          getComments();
          const commentInputValue = document.getElementById("add-comment");
          if (commentInputValue) {
            commentInputValue.value = "";
          }
          window.fbq("track", "Education Page Modal Comment is added.", {
            name: formData.firstName + formData.lastName,
            email: formData.email,
          });
          MySwal.fire({
            title: "Your comment is added.",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      } catch (e) {}
    } else if (educationCondition) {
      try {
        await patientRegister(formData);
        if (
          formData.registerStatus?.status === 200 ||
          formData.registerStatus?.status === 201
        ) {
          const response = await axios.post(`v1/public/blog-views`, {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            zipcode: formData.zipcode,
            type: "education",
          });
          if (response) {
            const commentInputValue = document.getElementById("add-comment");
            if (commentInputValue) commentInputValue.value = "";
            window.fbq(
              "track",
              "Education Page User Details have been saved.",
              {
                name: formData.firstName + formData.lastName,
                email: formData.email,
              }
            );
            MySwal.fire({
              title: "Your Details have been Saved!",
              icon: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
          closeEducationModal();
        }
      } catch (e) {}
    }
    setEducationPageModal(formData);
    if (!localStorage.getItem("mop-user"))
      localStorage.setItem("education-page", JSON.stringify(formData));
    else {
      localStorage.setItem("mop-user", JSON.stringify(formData));
    }
  };
  //USEEFFECTS
  useEffect(() => {
    setValue("firstName", educationPageModal?.firstName);
    setValue("lastName", educationPageModal?.lastName);
    setValue("email", educationPageModal?.email);
    setValue("zipcode", educationPageModal?.zipcode);
    setValue("password", "");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (educationPageModal) reset(educationPageModal);
    // eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <div
      className="modal fade pe-none"
      id="educationPageModal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <form onSubmit={handleSubmit(formSubmit)}>
        <div
          className="modal-dialog pe-none"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Sign Up
              </h4>
              <span
                className="education-page-modal-close float-right cursor-pointer"
                onClick={() => {
                  closeEducationModal();
                  setResults(null);
                }}
              >
                X
              </span>
            </div>
            <div className="modal-body education-page-modal">
              <div className="row">
                <div className="col-sm-6">
                  <SocialMediaLogin
                    provider={"google"}
                    onSuccess={onSuccess}
                    text="Google Login"
                  />
                </div>
                <div className="col-sm-6">
                  <SocialMediaLogin
                    provider={"facebook"}
                    onSuccess={onSuccess}
                    text="Facebook Login"
                  />
                </div>
                <div className="col-sm-12 mb-3 mt-2 loginor d-flex w-100 align-items-center">
                  <span>OR</span>
                </div>
                <div className="col-sm-6 mb-3">
                  <TextInput
                    errors={errors}
                    id="firstName_indiv"
                    register={register}
                    label="First Name"
                    placeholder="First Name"
                    mandatory={true}
                    name="firstName"
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <TextInput
                    errors={errors}
                    id="lastName_indiv"
                    register={register}
                    label="Last Name"
                    placeholder="Last Name"
                    mandatory={true}
                    name="lastName"
                  />
                </div>
                <div className={"col-sm-6 mb-3"}>
                  <TextInput
                    errors={errors}
                    id="zipcode_indiv"
                    register={register}
                    label="Zip Code"
                    placeholder="Zip Code"
                    mandatory={true}
                    name="zipcode"
                  />
                </div>
                <div className="col-sm-6 mb-3">
                  <TextInput
                    errors={errors}
                    id="email_indiv"
                    register={register}
                    label="Email Address"
                    placeholder="Email Address"
                    mandatory={true}
                    name="email"
                  />
                </div>
              </div>
              <label htmlFor="Password" className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <div className="input-group mb-3 bg-white">
                <input
                  type={isVisibile ? "text" : "password"}
                  id="password_indiv"
                  className={`form-control bg-transparent ${
                    errors.password && "is-invalid"
                  }`}
                  name="Password"
                  placeholder="Password"
                  {...register("password")}
                  onChange={(e) => setpassword(e.target.value)}
                  onClick={() => settypepassword(true)}
                />
                <span
                  className={`input-group-text border-top-right-radius cursor-pointer ${
                    errors.password && "border-danger"
                  }`}
                  onClick={() => {
                    setIsVisibile(!isVisibile);
                  }}
                >
                  {!isVisibile ? (
                    <BsEye className="text-muted" />
                  ) : (
                    <BsEyeSlash className="text-muted" />
                  )}
                </span>
              </div>
              <PasswordCheck typepassword={typepassword} password={password} />
              <br />
              <>
                Already have an account?{" "}
                <Link
                  to="/login"
                  className="text-decoration-none"
                  onClick={closeEducationModal}
                >
                  <span className="text-primary">Login</span>
                </Link>
              </>
              <br />
              <small>
                If you are a provider, Please{" "}
                <Link onClick={closeEducationModal} to="/usertype">
                  Sign Up Here
                </Link>
              </small>
            </div>
            <div className="modal-footer justify-content-between">
              <>
                <button className="btn btn-primary me-2" type="submit">
                  Submit
                </button>
                {educationCondition ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      closeEducationModal();
                      navigate(`/`);
                    }}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={closeEducationModal}
                  >
                    Cancel
                  </button>
                )}
              </>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EducationPageModal;
