import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import { AuthUser } from "../../constants/index";
import axios from "../../util/axiosInst";
import Audio from "./audio/Audio";
import Chat from "./chat/Chat";
import { socket } from "../../util/socketInst";

const OpenVirtualcare = ({
  children,
  id,
  type,
  mode,
  label,
  className,
  participantId,
  userId,
  callback,
}) => {
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [contactMode, setContactMode] = useState(null);
  const [token, setToken] = useState(null);
  const [role, setRole] = useState((AuthUser && AuthUser?.role) || "patient");

  const openVideoContent = (token) => {
    const windowFeatures =
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=959px,height=800px";
    let windowObjectReference = null;
    function openRequestedTab(url, windowName, windowFeatures) {
      if (windowObjectReference === null || windowObjectReference.closed) {
        windowObjectReference = window.open(url, windowName, windowFeatures);
      } else {
        windowObjectReference.focus();
      }
    }
    const url = `/virtualcare/video/room/${token}`;
    openRequestedTab(url, "Myorthopedic Problem - Virtualcare", windowFeatures);
  };

  const getVirtualcareData = async (id, type, mode) => {
    setShow(false);
    if (id && type && mode) {
      const { data } = await axios.post(`/v1/virtualcare/create/open`, {
        id: id,
        type: type,
        mode: mode,
        participantId: participantId,
      });
      if (data?.virtualcare) {
        setContactMode(data?.virtualcare?.mode);
        let virtualcareToken = "";
        let socketUserId = "";
        if (userId === participantId) {
          virtualcareToken = data?.virtualcare?.participantToken;
          socketUserId = String(data?.virtualcare?.participantId);
          setToken(data?.virtualcare?.participantToken);
        } else {
          virtualcareToken = data?.virtualcare?.organizerToken;
          socketUserId = String(data?.virtualcare?.organizerId);
          setToken(data?.virtualcare?.organizerToken);
        }
        if (String(data?.virtualcare?.mode).toUpperCase() === "VIDEO") {
          openVideoContent(virtualcareToken, socketUserId);
          socket.emit(socketUserId, {
            ...data?.virtualcare,
            isSocketEnabled: true,
          });
        } else {
          setShow(true);
        }
      }
    }
  };

  const handleJoinClick = async () => {
    await getVirtualcareData(id, type, mode);
  };

  const handleOnhide = () => {
    setShow(false);
    if (callback) {
      callback();
    }
  };

  const handleOnCallBack = (m) => {
    setShow(m);
    if (callback) {
      callback();
    }
  };

  return (
    <React.Fragment>
      <span onClick={handleJoinClick}>{children}</span>
      <Modal
        show={show}
        onHide={handleOnhide}
        fullscreen={fullscreen}
        backdrop="static"
        keyboard={false}
        animation={true}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          {contactMode && token && contactMode === "AUDIO" && (
            <Audio token={token} callback={handleOnCallBack} />
          )}
          {contactMode && token && contactMode === "CHAT" && (
            <Chat token={token} />
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default OpenVirtualcare;
