import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { userType } from "../../../../constants/index";
import Image from "../../../provider/components/custom/Image";

const CreateAccount = ({ onSubmit, loading }) => {
  const schema = yup
    .object({
      userType: yup.string().required("User type is required"),
    })
    .required();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    onSubmit(data);
  };

  return (
    <section>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="container mt-5 mb-5">
          <div className="row pt-md-2 px-md-5 text-center d-flex">
            <div className="col-sm-3">&nbsp;</div>
            <div className="col-sm-6 center-block">
              <div className="bg-white w-100 p-3 px-md-5 border rounded shadow-lg">
                <Image
                  local={true}
                  alt="My Orthopedic Problem"
                  className="width-8rem"
                />
                <div className="mb-3 mt-4">
                  <h4 className="text-black py-2">Select User Type</h4>
                  <select
                    className="form-select text-muted"
                    {...register("userType")}
                  >
                    <option value="">Select User Type</option>
                    {userType?.map((item) => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  {errors.userType && (
                    <p className="text-danger">{errors.userType.message}</p>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-3 text-white fw-bold"
                  disabled={!!loading}
                >
                  {loading ? "Loading..." : "Continue"}
                </button>
              </div>
            </div>
            <div className="col-sm-3">&nbsp;</div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default CreateAccount;
